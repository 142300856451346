.comment {
  margin: 0px 0px;
  padding-left: 15px;
  padding-right: 0px;
  position: relative;
}
.comment-header {
  display: flex;
  justify-content: space-between;
  cursor: pointer;
  padding: 4px 10px 4px 0px;
  width: 100%;
}
.comment-thread-hidden {
  /*display: none;*/
}
.comment-removed {
  text-align: center;
  font-style: italic;
  opacity: 0.5;
  color: maroon;
}
.comment-body {
  padding-left: 5px;
  padding-right: 5px;
}
.comment-body p {
  user-select: text !important;
}
span.comment-author.comment-is-op {
  font-weight: bold;
  color: #ff0a0a !important;
  display: flex;
}
span.comment-op-sign {
  background: #e90101;
  color: white;
  padding: 0px 5px;
  border-radius: 6px;
  font-size: 11px;
  display: flex;
  align-items: center;
  margin-left: 3px;
  font-weight: bold;
}

span.comment-author.comment-is-auto-mod {
  color: gray !important;
}
.comment.comment-is-auto-mod {
  border-color: gray !important;
  border-style: dashed;
}


.comment-body-injected-html a {
  padding: 0px 10px;
  border-radius: 10px;
  text-decoration: none;
  font-family: 'CoinbaseText';
  font-weight: bold;
}

[data-theme="light"] .comment-body-injected-html a {
  color: #007aff;
  background: rgb(0 0 0 / 23%);
}

[data-theme="dark"] .comment-body-injected-html a {
  color: #fecb06;
  background: rgba(58, 58, 58, 0.87);
}

[data-theme="light"] .comment-body-injected-html a .link-text {
  text-shadow: 0 0 6px #e1efff;
}

[data-theme="dark"] .comment-body-injected-html a .link-text {
  text-shadow: 0px 0px 5px rgb(254 203 6);
}
