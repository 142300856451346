.post-loader {
  display: flex;
  flex-direction: column;
  gap: 10px;
  box-sizing: border-box;
  padding: 3px;
  width: 100%;
  min-width: 100%;
  max-width: 100%;
}
