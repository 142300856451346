.thread-title-container {
  box-sizing: border-box;
  padding: 5px;
  padding-left: 25px;
  padding-bottom: 0;
  position: sticky;
  top: 0;
  box-shadow: rgba(0, 0, 0, 0.16) 0px 10px 36px 0px,
    rgba(0, 0, 0, 0.06) 0px 0px 0px 1px;
  z-index: 1;
  backdrop-filter: blur(30px) saturate(1.5);
  -webkit-backdrop-filter: blur(30px) saturate(1.5);
  background: linear-gradient(180deg, white, rgba(255, 255, 255, 0.5));
  padding-top: 80px;
  padding-bottom: 15px;
  padding-right: 20px;
  background: black;
}
.thread-sub-background {
  position: absolute;
  top: 0;
  left: 0;
  z-index: 1;
}
.thread-title-sub:hover {
  background-color: black;
  color: white;
}
.thread-title-sub {
  position: absolute;
  cursor: pointer;
  z-index: 3;
  color: #cbcbcb;
  background: rgb(0 0 0 / 20%);
  text-transform: lowercase;
  border-radius: 7px;
  padding: 5px 8px;
  font-size: 10px;
  bottom: 5px;
  opacity: 1;
  backdrop-filter: blur(10px) saturate(1.15);
  -webkit-backdrop-filter: blur(10px) saturate(1.15);
}
.thread-container {
  width: 100%;
  padding: 20px;
  word-wrap: break-word;
  padding-bottom: 80px;
  overflow-y: scroll;
}
.thread-comments-container {
  margin-top: 10px;
}
.thread-title {
  margin: 0;
  font-size: 15px;
  z-index: 2;
  position: relative;
  color: white;
  text-shadow: 4px 3px 8px black;
}
h5.thread-author {
  margin: 0;
  padding: 10px;
  font-style: italic;
  padding-left: 0;
}
.thread-image-container {
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 15px;
  overflow: hidden;
}
.thread-image {
  max-width: 100%;
}
.thread-actions {
  margin-top: 10px;
  margin-bottom: 10px;
}

/* MEDIA */
.thread-media-container {
  display: flex;
  justify-content: center;
  align-items: center;
}
video.thread-media-video-player {
  max-width: 100%;
}
.thread-youtube-container iframe {
  max-width: 100%;
}
.thread-header-source-url {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  box-sizing: border-box;
  padding: 7px;
  margin: 5px;
  margin-top: 15px;
  border-radius: 6px;
  background-color: #ededed;
  gap: 5px;
  cursor: pointer;
  user-select: none !important;
}
.thread-header-source-url:hover {
  opacity: 0.75;
}
.thread-header-source-url.is-copied {
  background-color: rgba(64, 192, 87, 0.1) !important;
}
.thread-header-source-url p,
.thread-header-source-url svg {
  color: gray;
  user-select: none !important;
}
.thread-header-source-url.is-copied p,
.thread-header-source-url.is-copied svg {
  color: rgba(64, 192, 87);
}
