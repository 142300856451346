.post {
  padding: 0;
  margin: 5px;
  border-radius: 0px;
  box-shadow: rgba(0, 0, 0, 0.05) 0px 6px 24px 0px,
    rgba(0, 0, 0, 0.08) 0px 0px 0px 1px;
  position: relative;
  box-sizing: border-box;
}
.post-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  cursor: pointer;
  width: 100%;
}
.post-header.selftext {
  margin-bottom: 10px;
  padding-bottom: 5px;
  border-bottom: 0.5px solid blueviolet;
}

.post-engagement-data {
  text-align: center;
  display: flex;
  align-items: center;
  padding: 5px;
}

.post-num-comments {
  color: gray;
  font-size: 0.75em;
}
.post-title {
  padding-left: 10px;
  font-size: 0.8em;
  text-align: left;
}

.post-selftext {
  max-width: 100%;
  overflow-wrap: anywhere;
  font-size: 0.75em;
  user-select: text !important;
}

.post-selftext.dimmed {
  color: rgb(186, 186, 186);
}
.post-selftext.exposed {
  color: rgb(11, 156, 218);
}

.post-date {
  opacity: 0.5;
  z-index: 0;
}
.post-date-day,
.post-date-full-date {
  margin-right: 10px;
}

/* Thumbnails */
.post-thumbnail-container {
  display: flex;
  align-items: center;
  justify-content: center;
  max-height: 60px;
  max-width: 60px;
  overflow: hidden;
  border-radius: 5px;
}
img.post-thumbnail {
  user-select: none;
  -webkit-user-drag: none;
  cursor: pointer;
}
img.post-thumbnail:hover {
  opacity: 0.75;
  transform: scale(0.95);
}
.post-photo-container {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 10px;
  margin-bottom: 10px;
  padding: 10px;
}
img.post-photo {
  max-width: 100%;
  border-radius: 8px;
}

/* Mod posts */
.post-moderator {
  border: 1px solid limegreen !important;
  background-color: rgba(21, 255, 21, 0.25) !important;
}
.post-chip-mod {
  color: rgb(36, 152, 36);
  font-weight: bold;
}
.post-stickied {
  background-color: rgba(255, 199, 17, 0.25);
  border: 2px solid rgb(255, 199, 17);
}

/* with Subreddit label */
.post-subreddit {
  user-select: none;
  display: flex;
  align-items: center;
  width: 100%;
  padding: 5px;
  font-size: 10px;
  font-weight: bold;
  color: gray;
}

.post-sub-container {
  display: flex;
  align-items: center;
}

.post-icon {
  padding-right: 5px;
}

.post-flex-wrapper {
  display: flex;
  padding: 5px;
}

.is-expanded .post-thumbnail-container {
  opacity: 0.5;
}

.content-container {
  width: 100%;
}

.post-num-comments {
  display: flex;
  align-items: center;
  gap: 3px;
  margin-left: 20px;
  color: rgb(11, 156, 218);
  font-weight: bold;
  font-size: 12px;
}

.post-ups {
  display: flex;
  align-items: center;
  gap: 3px;
}

/* Already visited styles */
.visited .content-container {
  opacity: 0.45;
  font-style: italic;
}
