body {
  margin: 0;
  height: 100vh;
  overflow: hidden;
}
#root {
  height: 100%;
}

* {
  box-sizing: border-box;
  font-family: sans-serif;
  user-select: none;
  -webkit-user-drag: none;
  -webkit-tap-highlight-color:  rgba(255, 255, 255, 0)!important;
  -webkit-touch-callout: none!important;
}
