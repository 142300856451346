.subreddit-display-card {
  font-size: larger;
  padding: 15px;
  display: flex;
  align-items: flex-end;
  position: relative;
  overflow: hidden;
  min-height: 150px;
  z-index: 1;
}

.background-container {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 1;
}

.icon-label-container {
  z-index: 3;
  display: flex;
  color: white;
  align-items: center;
}

.slim .icon-label-container {
  color: black;
}

.shadow-overlay {
  background: linear-gradient(180deg, transparent, rgba(0, 0, 0, 0.95));
  z-index: 2;
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 40%;
  transition: var(--default-animation);
  /* backdrop-filter: blur(10px); */
}
