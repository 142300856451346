.sub-header {
  font-size: 2em;
  padding: 12px;
  border-bottom: 2px solid;
  backdrop-filter: blur(15px);
  position: sticky;
  top: 0;
  display: flex;
  align-items: center;
  max-height: 100%;
}
.header-container {
  background-color: rgb(255 255 255 / 90%);
  position: sticky;
  top: 0;
  z-index: 1;
}

.sub-posts {
  flex: 1 1 auto;
  overflow-y: auto;
  min-height: 0px;
  margin-bottom: 70px;
}

.subContainer {
  overflow: scroll;
  display: flex;
  flex-direction: column;
  height: 100vh;
}
