.sub-quick-actions {
  position: fixed;
  top: 50%;
  right: 0;
  background: rgb(255 255 255 / 25%);
  border-radius: 5px 0px 0px 5px;
  box-shadow: rgba(0, 0, 0, 0.05) 0px 6px 24px 0px,
    rgba(0, 0, 0, 0.08) 0px 0px 0px 1px;
  backdrop-filter: blur(10px);
  padding: 5px;
  z-index: 99999999;
}
