.menu {
  position: fixed;
  bottom: 0;
  left: 0;
  height: 85px;
  background: linear-gradient(
    360deg,
    rgb(255 255 255 / 85%),
    rgb(255 255 255 / 60%)
  );
  width: 100%;
  backdrop-filter: blur(5px) saturate(1.5);
  -webkit-backdrop-filter: blur(5px) saturate(1.5);
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  padding: 0px 20px;
  padding-top: 10px;
  z-index: 9999;
  box-shadow: rgba(0, 0, 0, 0.05) 0px 6px 24px 0px,
    rgba(0, 0, 0, 0.08) 0px 0px 0px 1px;
}
